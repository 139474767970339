import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import ProTip from './ProTip';
import DanjiriNameBoxList from './DanjiriNameBoxList';
import { getAllDanjiriNameWithLatest, DanjiriNameWithLatest, updateDanjiriName } from './DanjiriInfo';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}

export default function App() {

  const [danjiriNames, setDanjiriNames] = React.useState<DanjiriNameWithLatest[]>()

  const getLatest = async () => {
    const dnames = await getAllDanjiriNameWithLatest()
    setDanjiriNames([...dnames])
  }

  const onEnabledChange = (UID: string, value: boolean) => {
    if (danjiriNames === undefined) return;
    const newDanjiriNameList = danjiriNames.map((danjiri) => {
        if (danjiri.UID === UID) {
            let updatedItem = {
                ...danjiri,
            }
            updatedItem.Enabled = value
            updateDanjiriName(updatedItem)
            return updatedItem
        } else {
            return danjiri
        }
    })
    setDanjiriNames(newDanjiriNameList)
  }

  React.useEffect(() => {
    (async() => {
      await getLatest()
    })()
  }, [])

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          ダッシュボード
        </Typography>
        <DanjiriNameBoxList names={danjiriNames} onEnabledChange={onEnabledChange} />
        <ProTip />
        <Copyright />
      </Box>
    </Container>
  );
}
