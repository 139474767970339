import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { FormControlLabel, Grid, Switch } from '@mui/material';
import { DanjiriInfo, DanjiriNameWithLatest } from './DanjiriInfo';


type DanjiriInfoBoxProps = {
  name: DanjiriNameWithLatest,
  onEnabledChange: (UID: string, value: boolean) => void
}

export default function DanjiriInfoBox(props: DanjiriInfoBoxProps) {
  return (
    <Grid item xs={12} sm={6} md={6} >
      <Card sx={{ minWidth: 275, mt: '1em' }}>
        <CardContent>
          <Typography sx={{ mb: '1em'}} variant="h5" component="div">
            IMEI: { props.name.UID}
          </Typography>
          <TextField
            sx={{ width: '100%', mb: '1em' }}
            label="名前"
            defaultValue={ props.name.Name }
            onChange={ (e) => console.log(e)}
          />
          <TextField
            sx={{ width: '100%', mb: '1em' }}
            label="Latitude"
            defaultValue={ props.name.Lat }
            disabled
          />
          <TextField
            sx={{ width: '100%', mb: '1em' }}
            label="Longitude"
            defaultValue={ props.name.Lng }
            disabled
          />
          <TextField
            sx={{ width: '100%', mb: '1em' }}
            label="Battery"
            defaultValue={ props.name.Bat }
          />
          <TextField
            sx={{ width: '100%' }}
            label="最終取得"
            defaultValue={ props.name.DateTime }
          />
        </CardContent>
        <CardActions>
          <FormControlLabel 
            sx={{ ml: '0.5em' }}
            control={<Switch checked={ props.name.Enabled } />} 
            label="表示"
            labelPlacement="start"
            onChange={(_, checked) => props.onEnabledChange(props.name.UID, checked)}
           />
        </CardActions>
      </Card>
    </Grid>
  );
}