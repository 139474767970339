import Api from "./Api";
import { AxiosError, AxiosResponse } from 'axios';

type DanjiriInfo = {
    lat: number;
    lng: number;
    Name: string;
    ID: number;
    dir: number;
    uid: string;
    datetime: string;
    bat: number;
}

type DanjiriName = {
    UID: string,
    Name: string,
    Place: string,
    Enabled: boolean,
    CreatedAt: string,
    UpdatedAt: string,
    DeletedAt: string | null,
}

type DanjiriNameWithLatest = {
    UID: string,
    Name: string,
    Place: string,
    Enabled: boolean,
    CreatedAt: string,
    UpdatedAt: string,
    DeletedAt: string | null,
    Lat: number,
    Lng: number,
    Bat: number,
    DateTime: string
}

const getDanjiriInfos = () : DanjiriInfo[] => {
    Api.get("/position/tadaoka/latest").then((res: AxiosResponse<DanjiriInfo[]>) => {
        const pos: DanjiriInfo[] = res.data
        return pos
    }).catch((err: AxiosError) => {
        console.log(err)  
    })
    return []
}

// const getDanjiriNames = async () : Promise<DanjiriName[]> => {
//     try {
//         const response : AxiosResponse<DanjiriName[]> = await Api.get("/manager/device/all")
//         const danjiri_name: DanjiriName[] = response.data
//         return danjiri_name
//     } catch(err) {
//         console.log(err)
//         return []
//     }
// }

const getDanjiriName = (uid: string) : DanjiriName | null => {
    Api.get(`/manager/device/${uid}`).then((res: AxiosResponse<DanjiriName>) => {
        const danjiri_name: DanjiriName = res.data
        return danjiri_name
    }).catch((err: AxiosError) => {
        console.log(err)
    })
    return null
}

const getAllDanjiriNameWithLatest = async () => {
    try {
        const response : AxiosResponse<DanjiriNameWithLatest[]> = await Api.get("/manager/device/all")
        const danjiri_name: DanjiriNameWithLatest[] = response.data
        return danjiri_name
    } catch(err) {
        console.log(err)
        return []
    }
}

const updateDanjiriName = async (dname_withlatest: DanjiriNameWithLatest) => {
    try {
        await Api.put(`/manager/device/${dname_withlatest.UID}`, dname_withlatest)
    } catch (err) {
        console.log(err)
    }
}

export type { DanjiriInfo, DanjiriNameWithLatest }
export { getDanjiriInfos, getAllDanjiriNameWithLatest, getDanjiriName, updateDanjiriName }