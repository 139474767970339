import Grid from "@mui/material/Grid";
import React, { Dispatch, SetStateAction } from "react";
import { DanjiriNameWithLatest } from "./DanjiriInfo";
import DanjiriInfoBox from "./DanjiriInfoBox";

type DanjiriNameBoxListProps = {
    names: DanjiriNameWithLatest[] | undefined,
    onEnabledChange: (UID: string, value: boolean) => void
}

export default function DanjiriNameBoxList(props: DanjiriNameBoxListProps) {
    const danjiriNames = props.names



    const danjiriBoxes = danjiriNames?.map((dname) => {
        return (
            <DanjiriInfoBox name={dname} key={dname.UID} onEnabledChange={props.onEnabledChange}/>
        )
    });
    return (
        <Grid container spacing={2}>
            { danjiriBoxes }
        </Grid>
    )
}